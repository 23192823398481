<template>
  <div
    v-if="minified"
    class="flex flex-col sm:flex-row justify-start border-b lg:border-none"
  >
    <RouterLink
      class="lg:border-r p-2 px-4 hover:bg-gray-100"
      :to="{name: 'products', params: {type: 'solpaneler'} }"
    >
      Solpaneler
    </RouterLink>
  
    <RouterLink
      class="lg:border-r p-2 px-4 hover:bg-gray-100"
      :to="{name: 'products', params: {type: 'inverter'} }"
    >
      Växelriktare / Inverter
    </RouterLink>
  
    <RouterLink
      class="lg:border-r p-2 px-4 hover:bg-gray-100"
      :to="{name: 'products', params: {type: 'takmontage'} }"
    >
      Takmontage
    </RouterLink>
  
    <RouterLink
      class="lg:border-r p-2 px-4 hover:bg-gray-100"
      :to="{name: 'products', params: {type: 'markstallningar'} }"
    >
      Markställningar
    </RouterLink>
  
    <RouterLink
      class="p-2 px-4 hover:bg-gray-100"
      :to="{name: 'products', params: {type: 'solparker'} }"
    >
      Solparker
    </RouterLink>
  </div>
  <div v-else>
    <div
      v-if="type"
    >
      <SolarPanels v-if="type === 'solpaneler'" />
      <SolarPark v-else-if="type === 'solparker'" />
      <Inverter v-else-if="type === 'inverter'" />
      <Assmenblies v-else-if="type === 'takmontage'" />
      <Ground v-else-if="type === 'markstallningar'" />
      <template v-else>
        <Container
          wide
          class="z-20 relative mt-8"
          padding="p-0"
        >
          <h1 class="text-4xl text-center font-extrabold mb-6">
            Produkter
          </h1>
          <div class="flex flex-wrap">
            <div class="p-2 w-full sm:w-1/2 flex-shrink-0">
              <ProductCategory
                class="mb-4"
                src="/solar-panels.png"
                :to="{name: 'products', params: {type: 'solpaneler'} }"
              >
                Solpaneler
              </ProductCategory>
            </div>
            <div class="p-2 w-full sm:w-1/2 flex-shrink-0">
              <ProductCategory
                class="mb-4"
                src="/inverter-2.png"
                :to="{name: 'products', params: {type: 'inverter'} }"
              >
                Växelriktare / Inverter
              </ProductCategory>
            </div>
            <div class="p-2 w-full sm:w-1/2 flex-shrink-0">
              <ProductCategory
                class="mb-4"
                src="/montering.png"
                :to="{name: 'products', params: {type: 'takmontage'} }"
              >
                Takmontage
              </ProductCategory>
            </div>
            <div class="p-2 w-full sm:w-1/2 flex-shrink-0">
              <ProductCategory
                class="mb-4"
                src="/markstallning.png"
                :to="{name: 'products', params: {type: 'markstallningar'} }"
              >
                Markställningar
              </ProductCategory>
            </div>
            <div class="p-2 w-full sm:w-1/2 flex-shrink-0">
              <ProductCategory
                class="mb-4"
                src="/127527.jpg"
                :to="{name: 'products', params: {type: 'solparker'} }"
              >
                Solparker
              </ProductCategory>
            </div>
          </div>
        </Container>
      </template>
    </div>
  </div>
</template>

<script>
import SolarPanels from './_components/SolarPanels'
import SolarPark from './_components/SolarPark'
import Inverter from './_components/Inverter'
import Assmenblies from './_components/Assmenblies'
import Ground from './_components/Ground'

export default {
  name: 'ProductsModule',
  components: {
    SolarPanels,
    SolarPark,
    Inverter,
    Assmenblies,
    Ground
  },
  props: {
    minified: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      type: null,
      typeText: {
        'solpaneler': 'Solpaneler',
        'solparker': 'Solparker',
        'inverter': 'Växelriktare / Inverter',
        'takmontage': 'Takmontage',
        'markstallningar': 'Markställningar'
      }
    }
  },
  created () {
    this.type = this.$route.params.type ? this.$route.params.type : null 
  }
}
</script>

<style>
.router-link-active {
  @apply font-bold;
}
</style>