<template>
  <div
    v-if="small"
    class="relative"
  >
    <div class="absolute text-white font-extrabold text-xl md:text-2xl bg-gray-900 bg-opacity-75 hover:bg-opacity-75 inset-0 z-30 flex items-center justify-center">
      <slot />
    </div>
    <BannerImage
      :src="src"
      ratio="aspect-w-16 aspect-h-7 sm:aspect-h-3"
    />
  </div>
  <RouterLink
    v-else
    class="relative rounded-md overflow-hidden"
    :to="to"
  >
    <div class="absolute text-white font-extrabold text-xl md:text-2xl bg-gray-900 hover:bg-custom-blue-800 bg-opacity-75 hover:bg-opacity-75 inset-0 z-30 flex items-center justify-center">
      <slot />
    </div>
    <BannerImage
      :src="src"
      ratio="aspect-w-16 aspect-h-9 sm:aspect-h-7"
    />
  </RouterLink>
</template>

<script>

export default {
  name: 'ProductCategory',
  props: {
    src: {
      type: String,
      default: ''
    },
    to: {
      type: Object,
      default: () => ({ name: 'products' })
    },
    small: {
      type: Boolean,
      default: false
    }
  }
}
</script>
