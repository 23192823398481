<template>
  <div
    :class="[size, boldiness]"
  >
    <slot />
  </div>
</template>

<script>

export default {
  name: 'SubTitle',
  props: {
    large: {
      type: Boolean,
      default: false
    },
    bold: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    boldiness () {
      if(this.bold) {
        return 'font-bold'
      }

      return 'font-medium'
    }, 
    size () {
      if(this.large) {
        return 'text-2xl'
      }

      return 'text-xl'
    }
  }
}
</script>
