<template>
  <div class="mx-auto max-w-[500px] mt-4">
    <p>Få ett gratis kostnadsförslag från en av våra solpanelsinstallatörer. Fyll i uppgifterna nedan för att bli kontaktad.</p>
    <div
      v-if="error"
      class="bg-red-100 px-4 py-2 text-red-900 my-2"
    >
      Ett fel inträffade när du försökte skicka ett meddelande. Vänligen se till att alla fält är ifyllda. Annars kan du skicka ett mail till <a
        href="mailto:info@windon.se"
        class="text-custom-blue-500"
        target="_blank"
      >info@windon.se</a> om felet kvarstår.
    </div>

    <div
      v-if="success"
      class="bg-green-100 px-4 py-2 text-green-900 my-2"
    >
      Tack för din förfrågan, vi återkommer så snart vi kan.
    </div>

    <form
      @submit.prevent="$emit('submit-quote')"
    >
      <div class="flex flex-col items-center mt-4">
        <div class="hidden">
          <Label for="name" />
          <input
            type="hidden"
            name="name"
            :Value="name"
            @input="$emit('name', $event)"
          >
        </div>
        <div class="flex flex-col w-full mt-2 max-w-[280px] text-left">
          <Label for="full_name">Namn</Label>
          <input
            required
            class="p-2 border border-custom-blue-600 text-custom-gray-900"
            type="text"
            name="full_name"
            :Value="fullName"
            @input="$emit('full-name', $event)"
          >
        </div>
        <div class="flex flex-col w-full mt-2 max-w-[280px] text-left">
          <Label for="email">Email</Label>
          <input
            required
            class="p-2 border border-custom-blue-600 text-custom-gray-900"
            type="email"
            name="email"
            :Value="email"
            @input="$emit('email', $event)"
          >
        </div>
        <div class="flex flex-col w-full mt-2 max-w-[280px] text-left">
          <Label for="phone">Telefon</Label>
          <input
            required
            class="p-2 border border-custom-blue-600 text-custom-gray-900"
            type="text"
            name="phone"
            :Value="phone"
            @input="$emit('phone', $event)"
          >
        </div>
        <div class="flex flex-col w-full mt-2 max-w-[280px] text-left">
          <Label for="postal_code">Postnummer</Label>
          <input
            required
            class="p-2 border border-custom-blue-600 text-custom-gray-900"
            type="text"
            name="postal_code"
            :Value="postalCode"
            @input="$emit('postal-code', $event)"
          >
        </div>
      </div>
      <ActiveButton class="mt-8">
        Ja tack, kontakta mig
      </ActiveButton>
    </form>
  </div>
</template>

<script>

export default {
  name: 'Quote',
  props: {
    name: { type: String, default: '' },
    fullName: { type: String, default: '' },
    email: { type: String, default: '' },
    phone: { type: String, default: '' },
    postalCode: { type: String, default: '' },
    loading: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    success: { type: Boolean, default: false }
  },
  emits: [
  'submit-quote',
  'name',
  'email',
  'full-name',
  'phone',
  'postal-code'
  ]
}
</script>
