import { createApp, h } from "vue"

import App from "./App.vue"
import router from "./js/router"
// import { i18n } from '@/js/language/'
// import store from './js/store'
import { createPinia } from "pinia"
import "@/assets/css/tailwind.scss"
import { registerComponents } from "./js/autoload"

import "./registerServiceWorker"
import Portal from "vue3-portal"

const app = createApp({
  setup: () => () => h(App),
})

// app.use(i18n)
app.use(createPinia())
app.use(router)
app.use(Portal)

registerComponents(app)

app.mount("#app")
