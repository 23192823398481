<template>
  <BaseButton class="bg-custom-orange-500 text-white">
    <slot />
  </BaseButton>
</template>

<script>

export default {
  name: 'ActiveButton',
  props: {
  }
}
</script>
