import Home from "@/views/Home"
import Products from "@/views/Products"
import Offer from "@/views/Offer"
import Installer from "@/views/Installer"
import About from "@/views/About"
import IR from "@/views/IR"

import Contact from "@/views/Contact"
import auth from "@/js/helpers/auth"
import { createRouter, createWebHistory } from "vue-router"
import $bus from "./helpers/bus.js"

const router = createRouter({
  mode: "history",
  history: createWebHistory(),
  base: process.env.BASE_URL,
  linkExactActiveClass: "active",
  scrollBehavior(to, from, savedPosition) {
    return { left: 0, top: 0 }
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/om-windon",
      name: "about",
      component: About,
    },
    {
      path: "/produkter/:type?",
      name: "products",
      component: Products,
    },
    {
      path: "/solpanelsoffert",
      name: "calculator",
      component: Offer,
    },
    {
      path: "/installatorer",
      name: "installer",
      component: Installer,
    },
    {
      path: "/ir/:selected?",
      name: "ir",
      component: IR,
    },
    {
      path: "/contact",
      name: "contact",
      component: Contact,
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.fullPath.indexOf("produkter") === -1) {
    $bus.emit("close-product-menu")
  }
  if (to.fullPath.indexOf("produkter") > -1) {
    $bus.emit("open-product-menu")
  }

  $bus.emit("close-mobile-menu")

  const tryToAccessUserProtectedUrlWithoutAccess =
    to.matched.some((m) => m.meta.protected) && !auth.isAuthenticated()

  if (tryToAccessUserProtectedUrlWithoutAccess) {
    auth.clearToken()
    next({ name: "login" })
  } else {
    next()
  }
})

export default router
