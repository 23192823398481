<template>
  <div
    class="flex flex-1 flex-col h-full bg-main-background font-standard font-medium font-base text-gray-900"
  >
    <component :is="layout === 'front' ? 'FrontLayout' : 'Layout'">
      <router-view
        :key="$route.fullPath"
        class="h-full flex flex-col flex-1"
      />
    </component>
  </div>
</template>

<script>
import 'boxicons/css/boxicons.min.css'

import Layout from '@/layout/Layout.vue'
import FrontLayout from '@/layout/FrontLayout.vue'

export default {
  name: 'App',
  components: {
    FrontLayout,
    Layout
  },
  computed: {
    layout () {
      return this.$route.meta.layout ? this.$route.meta.layout : 'standard'
    }
  }
}
</script>
