<template>
  <input
    :maxlength="maxlength"
    :minlength="minlength"
    :required="required"
    :type="type"
    :value="value"
    :placeholder="placeholder"
    :disabled="locked"
    :class="[classes,border,rounded,padding,iphoneClass,shadow, 'px-4 py-2 text-sm rounded-sm focus:outline-none focus:ring-0']"
    @keyup.enter="$emit('submit')"
    @input="$emit('input', $event)"
    @focus="$emit('focus', $event)"
  >
</template>

<script>

import { Capacitor } from '@capacitor/core'
import { isIphone } from '@/js/helpers/mobile'

export default {
  name: 'BaseInput',
  props: {
    required: { type: Boolean, default: false },
    type: { type: String, default: 'text' },
    value: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    classes: { type: String, default: '' },
    locked: { type: Boolean, default: false },
    rounded: { type: String, default: 'rounded-none' },
    shadow: { type: String, default: 'shadow-none' },
    padding: { type: String, default: 'px-4 py-2' },
    border: { type: String, default: 'border border-gray-300 dark:border-gray-300' },
    maxlength: { type: Number, default: 500 },
    minlength: { type: Number, default: 0 }
  },
  computed: {
    iphoneClass () {
      if ((Capacitor.getPlatform() === 'ios') || isIphone()) {
        return 'input-iphone-size'
      }

      return ''
    }
  }
}
</script>

<style lang="scss">
input::placeholder {
  font-size: 14px;
}
.input-iphone-size {
  font-size: 1em;
}
@media only screen and (max-width: 600px) {
  input::placeholder {
    font-size: 16px;
  }
}
</style>
