import { RepositoryFactory } from "@/api/repositoryFactory"
const repository = RepositoryFactory.get("quotes")
import { defineStore } from "pinia"
import { useSnackbarStore } from "./snackbar"

export const useQuotesStore = defineStore("quotes", {
  state: () => ({
    loading: false,
    error: false,
    success: false,
  }),
  actions: {
    init() {
      this.loading = false
      this.success = false
      this.error = false
    },
    async submitQuote(payload) {
      this.loading = true
      this.error = false
      try {
        const response = await repository.submitQuote(payload)

        if (response && response.status === 201) {
          this.success = true
        }
        this.loading = false
      } catch (e) {
        const snackbar = useSnackbarStore()
        this.error = true
        this.loading = false
        snackbar.snack({ type: "error", e: e, show: true, autoclose: true })
      }
    },
  },
})
