<template>
  <ContactForm
    :loading="contactStore.loading"
    :error="contactStore.error"
    :success="contactStore.success"
    :name="name"
    :full-name="fullName"
    :email="email"
    :subject="subject"
    :message="message"
    @name="name=$event.target.value"
    @full-name="fullName=$event.target.value"
    @email="email=$event.target.value"
    @subject="subject=$event.target.value"
    @message="message=$event.target.value"
    @submit-contact="submitContact"
  />
</template>

<script>

import ContactForm from './_components/ContactForm.vue'
import { useContactStore } from '@/stores/contact'

export default {
  name: 'ContactWindonModule',
  components: {
    ContactForm
  },
  setup () {
    const contactStore = useContactStore()

    return { contactStore }
  },
  data () {
    return {
      name: '',
      fullName: '',
      email: '',
      subject: '',
      message: ''
    }
  },
  mounted () {
    this.contactStore.init()
  }, 
  methods: {
    async submitContact () {
      let payload = {
        name: this.name,
        full_name: this.fullName,
        email: this.email,
        subject: this.subject,
        message: this.message
      }
      
      await this.contactStore.submitContact(payload)
    }
  }
}
</script>
