import { Capacitor } from '@capacitor/core'
import { detectBrowser } from '@/js/helpers/mobile'
import { defineStore } from 'pinia'

export const useAppStore = defineStore('app', {
  state: () => ({
    menu: true,
    test: 'test 123',
    browser: localStorage.browser ? localStorage.browser : detectBrowser
  }),
  getters: {
    useMenu: state => state.menu,
    platform: () => Capacitor.getPlatform(),
    isNative: () => Capacitor.isNativePlatform(),
    insideCompanyPage: state => state.insideCompanyPage,
    insidePrivateChat: state => state.insidePrivateChat,
    fixedHash: state => state.fixedHash
  },
  actions: {
    useMenuAction (useMenu) {
      this.menu = useMenu
    },
    setTestAction (newValue) {
      this.test = newValue
    }
  }
})
