<template>
  <div class="bg-custom-blue-500 text-white">
    <Container
      wide
      class="flex flex-col items-center justify-center"
    >
      <Logo
        mode="lightest"
        class="w-full py-8"
      />
      <Menu
        class="mb-8 flex flex-col sm:flex-row"
        hide-on-mobile
        investor
        light
      />
      <div class="mb-8">
        © {{ (new Date).getFullYear() }} Windon Energy Solutions
      </div>
    </Container>
  </div>
</template>

<script>

export default {
  name: 'Footer',
  props: {
  }
}
</script>
