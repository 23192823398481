<template>
  <header
    class="bg-custom-gray-100 text-gray-900 z-20 w-full"
  >
    <Container
      full
      padding="p-0"
      class="py-0 px-4 lg:px-16 w-full flex lg:items-center items-center justify-between z-20 relative"
    >
      <RouterLink :to="{name: 'home'}">
        <Logo class=" p-4" />
      </RouterLink>
      <Menu
        :show-product-menu="showProductMenu"
        @open-product-menu="showProductMenu = !showProductMenu"
      />
    </Container>
    <Container
      v-if="showProductMenu"
      padding="p-0"
      full
      class="border bg-white"
    >
      <Container
        padding="p-0"
        wide
      >
        <ProductsContainer minified />
      </Container>
    </Container>
  </header>
</template>

<script>


// import MenuContainer from '@/modules/menu'
import ProductsContainer from '@/modules/products'
import $bus from "@/js/helpers/bus.js"

export default {
  name: 'HeaderInternal',
  components: {
    ProductsContainer
  },
  props: {
    
  },
  data () {
    return {
      showProductMenu: false
    }
  },
  mounted () {
    const self = this
    $bus.on('close-product-menu', () => {
      self.showProductMenu = false
    })
    $bus.on('open-product-menu', () => {
      self.showProductMenu = true
    })

  }
}
</script>
