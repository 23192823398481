<template>
  <div class="heroRoot">
    <Container
      wide
    >
      <div class="flex flex-col lg:flex-row items-center">
        <div class="w-10/12 lg:w-6/12 lg:mb-20 relative z-20 py-4 mx-auto px-0 ">
          <h1 class="font-large lg:text-5xl md:text-5xl text-2xl leading-snug lg:leading-tight max-w-3xl">
            Kontakta Windon
          </h1>
          <p class="pt-6 pr-4 font-normal lg:text-lg text-base md:leading-8 leading-6 max-w-2xl">
            För allmänna frågor skriv ditt meddelande här eller maila oss via mailen nedan.
          </p>
        </div>
        <form
          class="my-10 w-10/12 lg:w-6/12"
          @submit.prevent="$emit('submit-contact')"
        >
          <div
            v-if="error"
            class="bg-red-100 px-4 py-2 text-red-900 mb-2"
          >
            Ett fel inträffade när du försökte skicka ett meddelande. Vänligen se till att alla fält är ifyllda. Annars kan du skicka ett mail till <a
              href="mailto:info@windon.se"
              class="text-custom-blue-500"
              target="_blank"
            >info@windon.se</a> om felet kvarstår.
          </div>

          <div
            v-if="success"
            class="bg-green-100 px-4 py-2 text-green-900 mb-2"
          >
            Tack för ditt meddelande, vi återkommer så snart vi kan.
          </div>
          <div>
            <div class="hidden">
              <BaseInput
                :maxlength="50"
                :minlength="3"
                :required="true"
                :placeholder="'Namn *'"
                type="hidden"
                :value="name"
                class="mb-2 w-full"
                :rounded="'rounded-md'"
                @input="$emit('name', $event)"
              />
            </div>
            <BaseInput
              :maxlength="50"
              :minlength="3"
              :required="true"
              :placeholder="'Namn *'"
              :value="fullName"
              class="mb-2 w-full"
              :rounded="'rounded-md'"
              @input="$emit('full-name', $event)"
            />
            <BaseInput
              :maxlength="50"
              :minlength="3"
              :required="true"
              :placeholder="'Email *'"
              :value="email"
              class="mb-2 w-full"
              :rounded="'rounded-md'"
              @input="$emit('email', $event)"
            />
            <BaseInput
              :maxlength="255"
              :minlength="3"
              :required="true"
              :placeholder="'Ämne *'"
              :value="subject"
              class="mb-2 w-full"
              :rounded="'rounded-md'"
              @input="$emit('subject', $event)"
            />
            <BaseTextarea
              :maxlength="255"
              :minlength="3"
              :value="message"
              :classes="'rounded-md text-sm w-full h-44'"
              :placeholder="'Meddelande *'"
              :resize="'resize-none'"
              @input="$emit('message', $event)"
            />

            <button
              :disabled="loading"
              :type="'submit'"
              class="mt-2 disabled:opacity-75 px-4 rounded bg-custom-blue-500 hover:bg-custom-blue-400 hover:underscore text-white"
            >
              <div class="text-xl py-2 flex justify-center items-center ">
                <CircleLoader
                  v-if="loading"
                  small
                  class="mr-2"
                /> Skicka
              </div>
            </button>
          </div>
        </form>
      </div>
    </Container>
  </div>
</template>

<script>
export default {
  name: 'ContactForm',
  props: {
    name: { type: String, default: '' },
    fullName: { type: String, default: '' },
    email: { type: String, default: '' },
    subject: { type: String, default: '' },
    message: { type: String, default: '' },
    loading: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    success: { type: Boolean, default: false }
  },
  emits: [
  'submit-contact',
  'name',
  'email',
  'full-name',
  'subject',
  'message'
  ]
}
</script>

<style scoped>
.errorMessage {
  color: red;
}
</style>
