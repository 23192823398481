<template>
  <Quote
    :loading="quotesStore.loading"
    :error="quotesStore.error"
    :success="quotesStore.success"
    :name="name"
    :full-name="fullName"
    :email="email"
    :phone="phone"
    :postal-code="postalCode"
    @name="name=$event.target.value"
    @full-name="fullName=$event.target.value"
    @email="email=$event.target.value"
    @phone="phone=$event.target.value"
    @postal-code="postalCode=$event.target.value"
    @submit-quote="submitQuote"
  />
</template>

<script>

import Quote from './_components/Quote'
import { useQuotesStore } from '@/stores/quotes'

export default {
  name: 'QuoteModule',
  components: {
    Quote
  },
  setup () {
    const quotesStore = useQuotesStore()

    return { quotesStore }
  },
  data () {
    return {
      name: '',
      fullName: '',
      email: '',
      phone: '',
      postalCode: ''
    }
  },
  mounted () {
    this.quotesStore.init()
  }, 
  methods: {
    async submitQuote () {
      let payload = {
        name: this.name,
        full_name: this.fullName,
        email: this.email,
        phone: this.phone,
        postal_code: this.postalCode
      }
      
      await this.quotesStore.submitQuote(payload)
    }
  }
}
</script>
