<template>
  <div
    class="relative flex flex-col h-full"
  >
    <div class="h-full bg-custom-blue-500 text-white p-8 pb-32 text-center">
      <BigTitle large>
        Offertförfrågan
      </BigTitle>
      <QuoteContainer />
    </div>
  </div>
</template>

<script>

import QuoteContainer from '@/modules/quote'

export default {
  name: 'Offer',
  components: {
    QuoteContainer
  }
}
</script>
