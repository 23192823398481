<template>
  <div
    class="relative flex h-full"
  >
    <div class="w-[300px] flex flex-col">
      <RouterLink
        :class="selected === '' ? 'font-bold bg-white' : ''"
        class="p-4 border-b"
        :to="{name: 'ir', params: {selected: ''}}"
      >
        Investerare
      </RouterLink>
      <RouterLink
        :class="selected === 'goals' ? 'font-bold bg-white' : ''"
        class="p-4 border-b"
        :to="{name: 'ir', params: {selected: 'goals'}}"
      >
        Finansiella mål
      </RouterLink>
      <RouterLink
        :class="selected === 'contact' ? 'font-bold bg-white' : ''"
        class="p-4 border-b"
        :to="{name: 'ir', params: {selected: 'contact'}}"
      >
        IR-kontakt
      </RouterLink>
    </div>
    <div class="w-full bg-white h-full p-4">
      <div v-if="selected === 'goals'">
        <BigTitle>Finansiella mål</BigTitle>
        <div class="w-full max-w-[1000px]">
          HODL har inte antagit några finansiella mål utan har endast som målsättning att inom 24 månader genomföra ett förvärv och att det förvärvade bolaget genom HODL blir noterat på Spotlight Stock Market. Därefter avser HODL att presentera finansiella mål utifrån den verksamhet som förvärvats och förutsättningarna för denna.
        </div>
      </div>
      <div v-else-if="selected === 'contact'">
        <BigTitle>IR-Kontakt</BigTitle>
        <div>Michaela Berglund, Styrelseordförande</div>
        <div class="mb-4">
          <a
            class="font-bold"
            href="mailto:mb@hodlspac.se"
          >mb@hodlspac.se</a>
        </div>
        
        <div>Vahid Toosi, VD</div>
        <a
          class="font-bold"
          href="mailto:v@hodlspac.se"
        >v@hodlspac.se</a>
      </div>
      <div v-else-if="selected === 'investor'">
        investor
      </div>
      <div v-else>
        <div
          v-if="showLoader"
          class="flex items-center justify-center p-4"
        >
          <CircleLoader black />
        </div>
        <iframe
          class="w-full"
          height="1000"
          src="https://ir.spotlightstockmarket.com/sv/bolag/irabout?CompanyID=9046"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore } from '@/stores/app'

export default {
  name: 'IR',
  setup () {
    const appStore = useAppStore()

    return { appStore }
  },
  data () {
    return {
      selected: '',
      showLoader: true
    }
  },
  mounted () {
    const self = this
    this.selected = this.$route.params.selected ? this.$route.params.selected : ''

    if(this.selected === '') {
      this.showLoader = true
    }

    setTimeout(() => {
      self.showLoader = false
    }, 2000)
  }
}
</script>
