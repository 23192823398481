<template>
  <div class="z-60 relative md:w-full">
    <Icon
      v-if="!hideOnMobile && isMobile"
      name="menu"
      class="block md:hidden text-4xl text-custom-gray-900 cursor-pointer"
      @click="show = true"
    />
    <div
      class="uppercase items-center z-20"
      :class="[light ? 'text-white' : 'text-custom-gray-900',
               isMobile && show ? 'fixed right-0 bg-white flex flex-col w-[250px] top-0 h-screen pt-10' : isMobile ? 'hidden' : 'w-full hidden md:flex ' + justify
      ]"
    >
      <Icon
        v-if="isMobile && show"
        name="x"
        class="text-4xl text-custom-gray-900 cursor-pointer absolute top-1 right-1"
        @click="show = false"
      />
      <div class="w-full flex flex-col md:flex-row items-center justify-start">
        <div
          :class="isMobile ? 'block text-left border-b w-full hover:bg-gray-100 p-4' : 'p-2 px-4 inline-block '"
          class="cursor-pointer"
          @click="$emit('open-product-menu')"
        >
          Produkter
        </div>
        <div
          v-if="isMobile && showProductMenu"
          class="ml-4"
        >
          <ProductsContainer
            minified
          />
        </div>
        <RouterLink
          :class="isMobile ? 'block text-left border-b w-full hover:bg-gray-100 p-4' : 'inline-block p-2 px-4'"
          :to="{name:'installer'}"
          @click="$emit('open-product-menu')"
        >
          Installatörer
        </RouterLink>
      </div>
      <div class="w-full flex flex-col md:flex-row justify-end items-center">
        <RouterLink
          :class="isMobile ? 'block text-left border-b w-full hover:bg-gray-100 p-4' : 'inline-block p-2 px-4'"
          :to="{name:'about'}"
          @click="$emit('open-product-menu')"
        >
          Om Windon
        </RouterLink>
        <RouterLink
          :class="isMobile ? 'block text-left border-b w-full hover:bg-gray-100 p-4' : 'inline-block p-2 px-4'"
          :to="{name:'contact'}"
          @click="$emit('open-product-menu')"
        >
          Kontakt
        </RouterLink>
      
    
        <RouterLink
          class="p-2 px-4 text-white bg-custom-blue-500 rounded-sm"
          :class="isMobile ? 'mt-4 ml-4 mr-4 block text-center' : 'inline-block '"
          :to="{name:'calculator'}"
          @click="$emit('open-product-menu')"
        >
          FÅ OFFERT
        </RouterLink>
        <a
          v-if="investor"
          href="https://windonenergygroup.se"
          target="_blank"
          class="p-2 px-4 inline-block"
        >
          För investerare
        </a>
      </div>
    </div>
    <div
      v-if="isMobile && show"
      class="fixed inset-0 bg-custom-gray-900 bg-opacity-25 h-screen z-10"
      @click="show = false"
    />
  </div>
</template>

<script>
import ProductsContainer from '@/modules/products'
import $bus from "@/js/helpers/bus.js"

export default {
  name: 'Menu',
  components: {
    ProductsContainer
  },
  props: {
    light: {
      type: Boolean,
      default: false
    },
    hideOnMobile: {
      type: Boolean,
      default: false
    },
    investor: {
      type: Boolean,
      default: false
    },
    showProductMenu: {
      type: Boolean,
      default: false
    },
    justify: {
      type: String,
      default: 'justify-end'
    }
  },
  data () {
    return {
      show: false,
      windowWidth: 0
    }
  },
  computed: {
    isMobile () {
      return this.windowWidth < 758
    }
  },
  mounted () {
    const self = this
    self.windowWidth = window.innerWidth
    window.addEventListener('resize', () => {
      self.windowWidth = window.innerWidth
    })

    $bus.on('close-mobile-menu', () => {
      self.show = false
    })

  }
}
</script>
