<template>
  <Header />
</template>

<script>

import Header from './_components/Header'

export default {
  name: 'HeaderModule',
  components: {
    Header
  },
}
</script>
