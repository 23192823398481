import usersRepository from "./real/users"
import newsRepository from "./real/news"
import contactRepository from "./real/contact"
import quotesRepository from "./real/quotes"

const repositories = {
  contact: contactRepository,
  quotes: quotesRepository,
  news: newsRepository,
  users: usersRepository,
}

export const RepositoryFactory = {
  get: (name) => repositories[name],
}

export default RepositoryFactory
