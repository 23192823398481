<template>
  <div
    class="relative flex flex-col"
  >
    <div class="relative">
      <h1 class="absolute inset-0 z-30 flex items-center justify-center text-white text-shadow lg:text-6xl text-3xl md:text-4xl font-extrabold">
        Om Windon
      </h1>
      <div class="bg-custom-gray-900 bg-opacity-50 absolute inset-0 z-20" />
      <BannerImage
        src="/about.jpg"
        ratio="aspect-w-16 aspect-h-7 md:aspect-h-5 lg:aspect-h-4"
      />
    </div>
    <Container
      wide
      class="z-20 relative"
    >
      <SubTitle>Om Windon</SubTitle>

      <p class="mb-4">
        Windon, grundat av Lennart Carlsen, är ett bolag som varit pionjärer inom förnybar energi, men har samtidigt till stor del verkat som ett familjebolag utan tillväxtagenda. Under 2022 påbörjades arbetet med att ta tillvara de möjligheter på marknaden som uppstått pga accelererade krav på omställning av energikällor, ökande energipriser och inte minst ett ökande intresse för decentraliserad energiproduktion till följd av kriget i Ukraina. Genom förvärvet kan ytterligare resurser tillföras för att skapa en tillväxtplan för Bolaget, som tillvaratar dessa möjligheter, samtidigt som Lennart Carlsen kvarstår som konsult till bolaget under ett antal år.
      </p>
      <p class="mb-4">
        Windon verkar i en bransch som är under stark tillväxt och genom noteringen så förväntar sig Bolaget en ökad varumärkeskännedom och stärkt offentlig profil. Bolaget räknar med att den publicitet som följer av att Bolaget blir noterat bedöms få en positiv påverkan och attraktionskraft hos nya och befintliga kunder, och öka takten vid rekrytering av nya återförsäljare.
      </p>
      <p class="mb-4">
        Bolaget ser noteringen som en kvalitetsstämpel som kan bidra till att stärka befintliga och nya affärsrelationer med såväl kunder som andra intressenter.
      </p>
      <p class="mb-4">
        Bolaget har en tillväxtplan som framförallt fokuserar på organisk tillväxt, men kommer också selektivt utvärdera potentiella förvärvsobjekt som kan bidra till att stärka bolagets position på marknaden, alternativt expandera till nya marknader. I detta sammanhang är en notering en styrka både i termer av att man blir en mer attraktiv potentiell partner, men också då tillgången till kapitalmarknaden kan bidra till att finansiera eventuella förvärv.
      </p>
      <p class="mb-4">
        Mer information för investerare finns på <a
          class="text-custom-orange-500"
          href="https://windonenergygroup.se"
          target="_blank"
        >Windon Energy Group</a>.
      </p>
    </Container>
    <Container
      wide
      class="z-20 relative"
    >
      <SubTitle>Windon är en systemleverantör</SubTitle>
      <p class="font-medium mb-6">
        Windon är en systemleverantör av solcellssystem där Bolaget tillhandahåller ett helt system med allt material som krävs för en komplett installation av en solcellsanläggning till installatörerna.
        Försäljningen sker till svenska installatörer som i sin tur säljer och installerar systemen hos slutkund, som till största del är svenska privatkunder.
      </p>
      <p class="font-medium mb-6">
        Bolaget strävar efter att kontinuerligt höja kunskapsnivån inom solceller bland alla sina återförsäljare genom att utbilda dessa på plats i Ödeshög. Genom att fortlöpande utbilda installatörerna ser Bolaget att försäljningen av anläggningar främjas och det ökar kvaliteten på installationerna hos slutkund, vilket minskar risken för reklamationer vilket i sin tur ökar lönsamheten för installatörerna. Och viktigast; ökad kundnöjdhet. 
      </p>
      <p class="font-medium mb-6">
        Då Bolaget historiskt har genomfört montage av solceller finns det en grundmurad know-how kring installation varför Bolaget kan bistå återförsäljare med kunskap även inom detta område.
      </p>
    </Container> 
  </div>
</template>

<script>
import { useAppStore } from '@/stores/app'

export default {
  name: 'About',
  setup () {
    const appStore = useAppStore()

    return { appStore }
  }
}
</script>
