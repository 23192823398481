<template>
  <div class="w-full flex flex-col flex-1 h-full">
    <ColumnWrapper>
      <RouterView :key="$route.fullPath" />      
    </ColumnWrapper>
    <Footer class="mt-24" />
  </div>
</template>

<script>

export default {
  name: 'Layout'
}
</script>
